.container{
    list-style: none;
    display: flex;
    flex-direction: row;
    li{
        a{
            display: block;
            padding: 2em;
            margin: 0.5em;
            font-size: 1.5em;
            text-transform: capitalize;
            border: 1px black solid;
            color: black;
            &:hover{
                background-color: blue;
                color: white;
            }
        }
    }
}