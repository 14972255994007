@import-normalize;
/* bring in normalize.css styles */
@import './_themevariables.scss';

body {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 16px;
}

.page-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h3 {
        text-align: center;
        font-weight: bold;
        font-size: 1.1rem;
    }

    &:has(>.sidebar, >.sidebar--fixed) {
        display: grid;
        grid-template-columns: 1fr 3fr
    }

    &>.sidebar {
        &--fixed {
            @extend .sidebar;
            height: 100vh; // experiment with this value, try changing to 110vh
            min-height: 200px;
            overflow: auto;
            position: -webkit-sticky;
            position: sticky;
            top: 5%;
        }
    }

    &>.main-content {
        height: 200vh;
        display: flex;
        flex-direction: column;
    }
}


.strong {
    font-weight: bold;
}

.disabled {
    color: rgba($text-color, 0.7);
}

button,
.button {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    outline: 0px;
    margin: 0px;
    vertical-align: middle;
    font-size: 0.875rem;
    line-height: 1.75;
    padding: 5px 15px;
    border-radius: 4px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: 1px solid TransparentColor($main-color, 0.5);
    color: rgb(25, 118, 210);

    &:hover {
        background-color: TransparentColor($main-color, 0.2);
        border: 1px solid $main-color;
    }

    &.small {
        padding: 3px 7px;
        font-size: 12px;
        line-height: 1;
    }

    &.primary {
        background-color: $secondary-color;
        color: white;
    }
}

input[type="checkbox"] {
    width: 1rem;
    height: 1rem;
    vertical-align: middle;
}

label.checkbox {
    vertical-align: middle;
}

// input[type="checkbox"] {
//     display: none;
//     &:hover + label:before {
//         opacity: 0.5;
//     }
//     &:checked + label {
//         color: #000;
//     }
//     &:checked + label:before {
//         border-color: transparent;
//         border-left-color: #2ecc71;
//         border-bottom-color: #2ecc71;
//         transform: rotate(-50deg);
//         width: 22px;
//         height: 12px;
//         top: 3px;
//     }
// }

// .checkbox {
//     display: inline-block;
//     position: relative;
//     padding-left: 20px;
//     line-height: 1.5;
//     cursor: pointer;
//     color: #111;
//     &:before {
//         z-index: 15;
//         content: "";
//         position: absolute;
//         left: 0;
//         top: 6px;
//         transition: all 0.3s ease;
//         cursor: pointer;
//         width: 1rem;
//         border-width: 4px;
//         border-style: solid;
//         border-color: #444;
//         height: 1rem;
//     }
// }

.actions-group {
    display: inline-flex;
    border-radius: 4px;

    .button {
        min-width: 40px;
        font-size: 0.5rem;
        line-height: 1.2;

        &:not(:first-child) {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            margin-left: -1px;
        }

        &:not(:last-child) {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right-color: transparent;
        }
    }
}

.searchbox {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    color: $text-color;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    border: 1px $gray-light solid;
    margin: 8px;

    border:focus {
        border-color: $main-color;
    }

    input {
        background: none;
        margin: 0px;
        display: block;
        min-width: 0px;
        width: 100%;
        padding: 0.5rem;
        border: 0;
    }
}

.skeleton {
    .text:after {
        content: "";
        display: inline-block;
        height: 20px;
        background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
        border-radius: 4px;
    }
}

.products-grid {
    width: 100%;

    &--topbar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
}

.cards {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
}



.ant-layout-header {
    background-color: $navbar-background-color;
    .supplier-logo{
        width: 150px;
        margin-left: auto;
        img{
            width: 100%;
        }
    }
}

.box {
    border: 1px $gray-dark solid;
    border-radius: 3px;

    .title {
        font-weight: bold;
        background-color: $gray-light;
        padding: 5px;
    }
}