@import './_themevariables.scss';

.products-details {
    // display: grid;
    // grid-template-columns: 1fr 2fr;
    padding: 10px 40px 20px;
    &--offline{
        @extend .products-details;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='150px' width='150px'><text transform='translate(20, 100) rotate(-45)' fill='rgba(0, 0, 0, 0.3)' font-size='20'>offline</text></svg>");
    }
    &__header {
        margin-bottom: 20px;
        border-bottom: 1px solid $gray-light
    }
    &__wrapper {
        display: grid;
        gap: 40px;
        grid-template-columns: 1fr 3fr;
        .sidebar {
            max-width: 400px;
            .basic {
                margin: 0px 0px 40px;
            }
            label {
                margin-bottom: 10px;
            }
        }
    }
    .product-updated {
        &::before {
            content: '';
            display: block;
            position: absolute;
            height: 0;
            width: 0;
            right: 0;
            top: 0;
            border-left: 35px solid transparent;
            border-bottom: 35px solid transparent;
            border-top: 35px solid $warning-color; // Make sure $warning-color is defined
        }
        
        &::after {
            content: 'U';
            display: block;
            position: absolute;
            color: white;
            right: 5px;
            top: 0;
            font-size: 14px; // Adjust font size if needed
            font-weight: bold;
        }
    }    
}

.description-item {
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    summary {
        cursor:pointer;
    }
    .description-label{
        font-size: 24px;
        text-transform: capitalize;
        font-weight: bold;
    }
}
.pricing {
    position: sticky;
    top: 10px;
    .extra-fields {
        .fields {
            margin-bottom: 20px;
            display: flex;
            flex-flow: column;
            label {
                margin-bottom: 0px;
            }
        }
    }

    .preview-buttons-container {
        margin: 20px auto;
        .preview-buttons {
            display: flex;
            font-size: 10px;
            gap: 10px;
            margin: 20px;
            flex-direction: row;
            justify-content: flex-end;
            flex-wrap: wrap;
            align-items: center;
            a{
                line-height: 1.5;
                padding: 5px 8px;
                color:$text-color;
            }
        }
    }
}