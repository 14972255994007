@import '../_themevariables.scss';

.Component {
    display: flex;
    min-width: 400px;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    overflow: hidden;
    padding: 10px;
    border: 1px $gray-light solid;
    border-radius: 5px;
    margin: 10px;
    &__list {
        overflow: auto;
        .List__item {
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 0px 10px;
            border-bottom: 1px #eee solid;
            align-items: stretch;
            box-sizing: border-box;
            font-weight: bold;
            // &:nth-child(odd):not(&--selected){
            //     background-color: $gray-light;
            // }
            &--selected {
                background-color: rgb(25, 118, 210, 0.08);
                &:hover {
                    background-color: TransparentColor($main-color, 0.12);
                }
            }
            &--mapped {
                font-weight: normal;
            }
            &:hover {
                background-color: rgba(0, 0, 0, 0.04) !important;
            }

            &__head {
                cursor: pointer;
                user-select: none;
                vertical-align: middle;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                text-align: left;
                line-height: 1.5rem;
                transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            }
            &__icon {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                text-align: left;
                width: 1.5em;
                transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            }
            &__name {
                flex: 1 1 auto;
                min-width: 0px;
                margin-top: 4px;
                margin-bottom: 4px;
                display: flex;
                justify-content: space-between;
                font-size: 0.9vw;
                line-height: 1;
            }
            &__details {
                display: none;
            }
        }
    }
}