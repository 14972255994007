@import '../_themevariables.scss';

.Component {
    display: flex;
    flex-direction: row;
    gap: 0;
    flex: 1;
    &__details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex:1;
        &--wrapper{
            padding: 10px;
            margin: 10px;
            border: 1px $gray-light solid;
        }

        .changesWrapper{
            
        }
    }
    .MappingListChanges{
       border:1px $gray-dark solid; 
       padding:0;
       li{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: $text-color;
        padding:5px;
        &:not(:last-child){
            border-bottom: 1px $gray-dark solid;
        }
        &:nth-child(odd){
            background-color: $gray-light;
        }
       }
    }
}