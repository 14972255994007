.container{
    margin-bottom: 32px;
    .row{
        display: none;
        display: grid;
        grid-template-columns:repeat(5, 70px);
        justify-content: space-between;
        align-items: center;
        gap: 12px 12px;
        padding: 8px 12px;
        border-radius: 4px;
    
        &:nth-child(odd) {
            background: #ececec;
        }
        &:nth-child(1) {
            background: #cdbebe;
        }

        &>div {
            margin: 4px 0;
            text-align: center;
            input {
                border: 1.5px solid #216d49;
                border-radius: 4px;
            }
        }
        &.heading{
            font-weight: bold;
            margin-bottom: 10px;
        }
        input{
            width: 100%;
        }
        .margins {
            display:flex;
            gap: 2px;
        }
    }
}