@import '../_themevariables';

.Card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 300px;
    margin: 5px;
    text-align: center;
    font-family: arial;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;    
    &.offline{
      opacity: 0.6;
    }
    &.updated:before{
      content:'';
      display: block;
      position: absolute;
      height: 0;
      width: 0;
      right: 0;
      top: 0;
      border-left: 35px solid transparent;
      border-bottom: 35px solid transparent;
      border-top: 35px solid $warning-color;
    }
    &.updated:after{
      content: 'U';
      display: block;
      position: absolute;
      color: white;
      right: 5px;
      top: 0;
    }
    &.publish:before{
      content:'';
      display: block;
      position: absolute;
      height: 0;
      width: 0;
      right: 0;
      top: 0;
      border-left: 35px solid transparent;
      border-bottom: 35px solid transparent;
      border-top: 35px solid $secondary-color;
    }
    &.publish:after{
      content: 'P';
      display: block;
      position: absolute;
      color: white;
      right: 5px;
      top: 0;
    }
    &.managed:before{
      content:'';
      display: block;
      position: absolute;
      height: 0;
      width: 0;
      right: 0;
      top: 0;
      border-left: 35px solid transparent;
      border-bottom: 35px solid transparent;
      border-top: 35px solid $main-color;
    }
    &.managed:after{
      content: 'M';
      display: block;
      position: absolute;
      color: white;
      right: 5px;
      top: 0;
    }
    .date{
      font-size: 0.8rem;
      align-self: flex-start;
      padding: 5px 0 0 5px;
      &--published{
        @extend .date;
        color: green;
      }
    }
    .content{
      padding: 15px;
      width: 100%;
      box-sizing: border-box;
    }
    .name{
      font-size: 16px;
      margin-top: 10px;
      /* font-weight: bold; */
    }
    .price {
      color: grey;
      font-size: 22px;
      margin-top: 10px;
    }
    .colors {
      margin-top: 10px;
    }
    .itemnumber{
      margin-top: 10px;      
    }
    .inkColor{
      position: absolute;
      left: 1rem;
      top: 0;
    }
    button {
        border: none;
        outline: 0;
        padding: 12px;
        color: white;
        background-color: #000;
        text-align: center;
        cursor: pointer;
        width: 100%;
        font-size: 18px;
        &:hover {
            opacity: 0.7;
          }
      }

      &.skeleton{
        .content{
          width: 100%;
        }
        .image, .text{
          background: #eee;
          background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
          background-size: 200% 100%;
          animation: 1.5s shine linear infinite;
        }
        .text{
          border-radius: 5px;
          height: 25px;
          margin-top: 10px;
        }
        .image{
          width: 100%;
          padding-top: 100%;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
  }

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }