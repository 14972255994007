.swatch{
    user-select: none;
    display: inline-block;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: 1px white solid;
    vertical-align: middle;
    border-radius: 50%;
    &.drop{
        width: 50px;
        height: 50px;
        border-radius: 0 50% 50% 50%;
        transform: rotate(45deg);
        margin-top: 20px;
    }
}