@import '../themevariables.scss';

.selectedImages {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 5px;
    gap: 1px;
    position: relative;

    .selectedImageItem {
        box-shadow: 1px 0 0 0 #e9ebeb, 0 1px 0 0 #e9ebeb, 1px 1px 0 0 #e9ebeb,
            /* Just to fix the corner */
            1px 0 0 0 #e9ebeb inset, 0 1px 0 0 #e9ebeb inset;
        cursor: move;
    }
}

.images {
    display: grid;
    align-content: space-between;
    justify-content: space-around;
    align-items: center;
    max-height: 100vh;
    overflow-y: auto;
    grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
    
    .selectableImage {
        position: relative;
        box-shadow: 1px 0 0 0 #e9ebeb, 0 1px 0 0 #e9ebeb, 1px 1px 0 0 #e9ebeb,
            /* Just to fix the corner */
            1px 0 0 0 #e9ebeb inset, 0 1px 0 0 #e9ebeb inset;
        z-index: 1;
        &:hover {
            box-shadow: 0px 0px 6px #afafaf;
            z-index: 2;
        }
        
        &--selected {
            @extend .selectableImage;
            border: 1px $main-color solid;
            box-shadow: 0px 0px 6px $main-color;

            &:before {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 30px 30px 0px 0;
                border-color: $main-color transparent transparent transparent;
                left: 0;
                top: 0;
                position: absolute;
            }

            &::after {
                content: '';
                width: 0;
                height: 0;
                left: 0;
                top: 0;
                position: absolute;
                display: inline-block;
                margin-left: 6px;
                margin-top: 2px;
                transform: rotate(45deg);
                height: 12px;
                width: 6px;
                border-bottom: 3px solid white;
                border-right: 3px solid white;
            }
        }
        
        label {
            display: block;
            cursor: pointer;
            &.new{
                @extend label;
                &:before{
                    content:'';
                    display: block;
                    position: absolute;
                    height: 0;
                    width: 0;
                    right: 0;
                    top: 0;
                    border-left: 35px solid transparent;
                    border-bottom: 35px solid transparent;
                    border-top: 35px solid $warning-color;
                }
                &::after{
                    content: 'N';
                    display: block;
                    position: absolute;
                    color: white;
                    right: 5px;
                    top: 0;
                }
            }
            img {
                min-width: 190px;
            }
        }
        
        input[type="checkbox"] {
            display: none
        }
        
        input[type="checkbox"]:checked+label {
            // border:1px $main-color solid;
            // &:before{
            //     content: '';
            //     width: 0;
            //     height: 0;
            //     border-style: solid;
            //     border-width: 30px 30px 0px 0;
            //     border-color: $main-color transparent transparent transparent;
            //     left: 0;
            //     top: 0;
            //     position: absolute;
            // }
            // &::after{
            //     content: '';
            //     width: 0;
            //     height: 0;
            //     left: 0;
            //     top: 0;
            //     position: absolute;
            //     display: inline-block;
            //     margin-left: 6px;
            //     margin-top: 2px;
            //     transform: rotate(45deg);
            //     height: 12px;
            //     width: 6px;
            //     border-bottom: 3px solid white;
            //     border-right: 3px solid white;
            // }
        }

        input[type="checkbox"]+label {}
    }
}