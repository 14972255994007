$text-color: #333;
$main-color: #ee3741;
$warning-color: #ffcc00;
$secondary-color: #008f51;
$gray-light: #efefef;
$gray-dark: #9b9b9b;
$navbar-background-color: #001529;

@function TransparentColor($color, $opacity) {
    @return rgba($color, $opacity);
}