.excludedProduct {
    display: flex;
    justify-items: stretch;
    justify-content: space-between;
    align-items: center;

    &>span {
        min-width: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &>input {
        max-width: 50px;
    }
}