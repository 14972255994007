@import '../_themevariables.scss';

.noPrinting{
    float: left;
}
.productSpecifications {
    margin-bottom: 40px;
}
.specificationHeading {
    font-size: 24px;
    text-transform: capitalize;
    font-weight: bold;
}
.specificationList {
    gap: 10px;
    display: grid;
    grid-template-columns: 50% 50%;
    font-size: 16px;
    padding-left:0px;
    .specificationMapping {
        border-radius: 4px;
        flex: 1;
        flex-basis: 50%;
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 4px 12px;
        background: $gray-light;
        position: relative;
        &.new:before{
            content:'';
            display: block;
            position: absolute;
            height: 0;
            width: 0;
            right: 0;
            top: 0;
            border-left: 25px solid transparent;
            border-bottom: 25px solid transparent;
            border-top: 25px solid $warning-color;
        }
        &.new:after{
            content: 'N';
            display: block;
            position: absolute;
            color: white;
            right: 5px;
            top: 0;
            font-size: 9px;
        }
        &.updated:before{
            content:'';
            display: block;
            position: absolute;
            height: 0;
            width: 0;
            right: 0;
            top: 0;
            border-left: 25px solid transparent;
            border-bottom: 25px solid transparent;
            border-top: 25px solid $warning-color;
        }
        &.updated:after{
            content: 'U';
            display: block;
            position: absolute;
            color: white;
            right: 5px;
            top: 0;
            font-size: 9px;
        }
        .stockLevel{
            color: #d56565;
            font-size: 0.8rem;
        }
        .checkboxSpec {
            gap: 10px;
            display: flex;
            width: 100%;
            align-items: center;
            .swatch {
                // width: 24px;
                // height: 24px;
                border-radius: 50%;
            }
            .text {
                display: flex;
                flex-flow: column;
                flex: 1;
            }
        }
        .supplierSpec{
            font-weight: 500;
        }
        .cmsSpec{
            font-weight: 400;
            color: $gray-dark;
            font-style: italic;
        }
    }
}
